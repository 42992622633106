.edit_post{
    text-align: center;
}

.edit_post h2{
    font-size: 2.2em;
}

.edit_post p{
    color: #AAA;
    margin-bottom: 2em;
}

.edit_post .preview_title{
    margin-bottom: .2em;
    color: #000;
    font-weight: bold;
}

.image_preview{
    max-width: 100%;
    margin-bottom: 10px;
    max-height: 300px;
}