.post_container{
    text-align: center;
}

.post_container h3{
    margin-bottom: .2em;
}

.post_container .tags{
    display: flex;
    justify-content: center;
}

.post_container img{
    max-height: 450px;
    max-width: 600px;


}

.tags p{
    margin-right: 1em;;
}

.tags span{
    font-weight: bold;
}