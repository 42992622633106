.post_detail{
    margin-bottom: 2em;
}

.post_detail img{
    max-width: 600px;
    max-height: 500px;
  
}

.post_detail h2{
    margin-bottom: .4em;
}

.post_detail .tags{
    margin-bottom: 1.2em;
    display: flex;
}

.tags p{
    margin-right: .5em;
}

.tags span{
    font-weight: bold;
}

.createdby{
    font-style: italic;
    color: #444;
    font-size: o.8em;
    margin-bottom: 1em;
}