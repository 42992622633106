.create_post{
    text-align: center;
}

.create_post h2{
    font-size: 2.2em;
}

.create_post p{
    color: #AAA;
    margin-bottom: 2em;
}