.dashboard{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboard h2{
    font-size: 2.2em;
    margin-bottom: .5em;
}

.dashboard p{
    color: #AAA;
    margin-bottom: 1em;
}

.noposts{
    text-align: center;
}

.noposts p{
    margin-bottom: 1.5em;
}

.noposts a{
    padding: 10px 25px;
}

.post_header{
  
    font-weight: bold;
    border-bottom: 2px solid #CCC;
  
}

.post_header, .post_row{
    display: flex;
    justify-content: space-between;
    width: 80%;
    padding: 10px;
}

.post_row{
   
    border-bottom: 1px solid #eee;
    align-items: center;
    
}

.post_row p{
    color: #000;
}

.post_row button,
.post_row a {
    margin: 0 5px;
    height: 30px;
    width: 100px;
    font-size: 0.7em;
}