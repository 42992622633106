.search_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.search_container h1{
    margin-bottom: 1em;
}

.search_container p{
    margin-bottom: 30px;
}

.noposts{
    text-align: center;
}